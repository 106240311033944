const config = {
	api: {
		idm: {
			baseUrl: 'https://idm-stage.ax-semantics.com/v1/'
		},
		quest: {
			baseUrl: 'https://mcgonagall-test.qax.io/'
		}
	},
	auth: {
		clientID: 'AJqKziTZloFiAkexBwkX5AqXUnr35Gbu',
		domain: 'ax-semantics-stage.eu.auth0.com'
	},
	cockpit: {
		baseUrl: 'https://cockpit.test.qax.io/',
	},
	intercom: {
		app_id: '56a54bf3818673484d09c5551b819d9daaedd377'
	},
	woopra: {
		qualitygate: 'test_audit_qualitygate',
		amountprojects: 'test_audit_amountprojects'
	},
	zapier: {
		baseUrl: 'https://hooks.zapier.com/hooks/catch/1019370/o52je4u/silent/'
	}
}

export default config
